import React from 'react';
import { ArticleCard } from 'ui/cards';
import { Grid, Cell } from 'ui';
import { GridContainer } from 'ui/containers';


const ArticleCards = ({ articles, background,variant }) => {

  return (
    <GridContainer variant="explore">
    {(articles || []).map((article, index) => (
      <Grid>
        <Cell sizes={{ default: 1, desktop: 1 }}>
          <ArticleCard 
            {...article}
            background= {background}
            variant={variant}
          />
        </Cell>
      </Grid>
    ))}
    </GridContainer>
  );
};

export default ArticleCards;
