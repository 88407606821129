import styled, { css } from 'styled-components';
import highlighting from '../assets/images/highlighting.png';
import React from 'react';
import { media } from 'ui';
import { API_URL } from 'constants';

export default function HeroHeader(props) {
  const MainTitle = styled.h1`
    color: #fff;
    max-width: 15em;
    margin: 0;
    ${({ theme }) => theme.fontStyles.h1Ultra};
  `;

  const SubTitleContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content:center;
    background-image:url('${highlighting}');
    background-repeat: no-repeat;
    background-size:100% 100%;
    margin-top:0px !important;
    padding:15px;
  `;

  const PictureBanner = styled.div`
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    height: 400px;
    border-radius: 50px;
    background-image: url('${props.country ?  props.defaultPicture ? props.image : `${API_URL}/${props.image}` : props.image}');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    ${props =>
      props.sustainable &&
      css`
        height: 530px;
        ${media.mobile`
          height: 330px;
        `}
      `}
  `
  const SubTitleText = styled.h6`
    color: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    ${({ theme }) => theme.fontStyles.h1Ultra};
  `;

  return (
    <div className="bg-white pt-16 md:pt-0" style={{position: 'relative', zIndex: '1'}}>
      <PictureBanner sustainable={props.sustainable}>
        <div className="h-full flex items-center justify-center">
          {props.children ? props.children : (
            <div className="text-center">
              <MainTitle><span className={props.titleClass}>{props.title}</span></MainTitle>
              {props.subtitle && (
                <SubTitleContainer>
                  <SubTitleText>{props.subtitle}</SubTitleText>
                </SubTitleContainer>
              )}
            </div>
          )}
        </div>
      </PictureBanner>
    </div>
  )
}
