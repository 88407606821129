import React from 'react';
import styled, { css } from 'styled-components';
import { Link as RawLink } from '@reach/router';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

const Wrapper = styled(List)`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(2)};
  bottom: 0;
  z-index: 1;
`;

const Item = styled.li`
  display: inline-block;

  + li {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${props =>
    props.ellipsis &&
    css`
      padding-left: ${({ theme }) => theme.spacing()};
      padding-right: ${({ theme }) => theme.spacing()};
      color: ${({ theme }) => theme.primary};
    `};
`;

const Link = styled(RawLink)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  min-width: 2em;
  color: ${({ theme }) => theme.primary};
  display: block;
  transition: background-color 150ms ease;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    border-radius: 4px;
  }
`;

const NonLink = styled.span`
  padding: ${({ theme }) => theme.spacing(0.5)};
  min-width: 2em;
  display: block;
  color: ${({ theme }) => theme.separator};
  white-space: nowrap;
`;

const ActivePage = styled(NonLink)`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 19px;
  color: #fff;
  width:40px;
  height:40px;
`;

const Pagination = ({ page, totalPages, pageLinkBuilder }) => {
  if (!totalPages || totalPages === 1) {
    return null;
  }

  return (
    <Wrapper>
      <Item>
        {page > 1 ? (
          <Link to={pageLinkBuilder(page - 1)}>
            <MdChevronLeft />
          </Link>
        ) : (
          <NonLink>
            <MdChevronLeft />
          </NonLink>
        )}
      </Item>
      {page !== 1 && (
        <Item>
          <Link to={pageLinkBuilder(1)}>1</Link>
        </Item>
      )}
      {page > 4 && <Item ellipsis>…</Item>}
      {page > 3 && (
        <Item>
          <Link to={pageLinkBuilder(page - 2)}>{page - 2}</Link>
        </Item>
      )}
      {page > 2 && (
        <Item>
          <Link to={pageLinkBuilder(page - 1)}>{page - 1}</Link>
        </Item>
      )}
      <Item>
        <ActivePage>{page}</ActivePage>
      </Item>
      {page + 1 < totalPages && (
        <Item>
          <Link to={pageLinkBuilder(page + 1)}>{page + 1}</Link>
        </Item>
      )}
      {page + 2 < totalPages && (
        <Item>
          <Link to={pageLinkBuilder(page + 2)}>{page + 2}</Link>
        </Item>
      )}
      {page + 3 < totalPages && <Item ellipsis>…</Item>}
      {page !== totalPages && (
        <Item>
          <Link to={pageLinkBuilder(totalPages)}>{totalPages}</Link>
        </Item>
      )}
      <Item>
        {page < totalPages ? (
          <Link to={pageLinkBuilder(page + 1)}>
            <MdChevronRight />
          </Link>
        ) : (
          <NonLink>
            <MdChevronRight />
          </NonLink>
        )}
      </Item>
    </Wrapper>
  );
};

export default Pagination;
