import { get, getPaginated, FEELINGO_API } from './';
import { getPaginationParams } from './utils';

export const getArticles = ({ language, size, number,sort, filter }) =>
  getPaginated(
    '/api/v1/blogs',
    {
      lang: language,
      includes: ['picture','thematic'],
      ...getPaginationParams({ size, number }),
      filter,
      sort
    },
    FEELINGO_API,
  );

export const getThematics = ({ language }) =>
  get('/api/v1/thematics', { lang: language });
