import React from 'react';
import styled from 'styled-components';
import feelingoLoader from '../assets/images/feelingoLoader.gif';

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

const LoadingLabel = styled.span`
  margin-top: ${({ theme }) => theme.spacing(-3)};
  color: ${({ theme }) => theme.textLighter};
`;

const Spinner = ({ label }) => (
  <SpinnerWrapper>
    <img
      style={{ maxHeight: '240px', maxWidth: '200px' }}
      src={feelingoLoader}
      alt="loading..."
    />
    <LoadingLabel>{label || 'Chargement...'}</LoadingLabel>
  </SpinnerWrapper>
);

export default Spinner;
