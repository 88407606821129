import { get } from './';

export const getCountries = ({ language }) =>
  get('/api/v1/countries/countries_with_article', {
    lang: language,
  });

export const getCountry = ({ countryId, language }) =>
  get('/api/v1/countries/' + countryId, {
    lang: language,
  });
