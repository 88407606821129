import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useSearchParam, useLocation } from 'react-use';
import { useAsync } from 'react-async';
import qs from 'qs';
import { get } from 'lodash';
import sanitizeHtml from 'sanitize-html';
import SEO from 'components/seo';
import Layout from '../components/Layout';
import { Container } from '../ui/containers';
import ArticleCards from 'components/ArticleCards';
import { useTranslation } from 'react-i18next';
import carnetDeVoyage from '../assets/images/carnetDeVoyage.jpg';
import PageContext from 'contexts/page';
import { getArticles } from 'api/articles';
import Spinner from 'components/Spinner';
import Pagination from 'components/Pagination';
import { useQueryParams } from 'hooks';
import { useDataLayerBuilder } from '@tymate/react-gtm';
import Filters from '../components/BlogFilters';
import { TitleContainer } from 'ui/containers';
import HeroHeader from '../components/HeroHeader';

const BackgroundLight = styled.div`
${props =>
  props.variant === 'article' &&
  css`
  background-color:#FFF !important;
`}
  display: block;
  height: 100%;
  background-color: ${({ theme }) => theme.backgroundLight};
`;

const Blog = () => {
  const { t } = useTranslation();
  const { language } = useContext(PageContext);
  useDataLayerBuilder(() => ({
    pageType: 'blog',
  }));

  const queryParams = useQueryParams();
  const location = useLocation();

  const page = Number(useSearchParam('page')) || 1;

  const { thematic: thematicId,countryId  } = queryParams;

  const params = useMemo(
    () => ({
      number: page,
      filter: {
        countryId: countryId ? countryId : '',
        thematicId: thematicId ? thematicId : ''
      },
      sort: thematicId? 'position': undefined
    }),
    [page, countryId, thematicId],
  );

  const { data, isLoading } = useAsync({
    promiseFn: getArticles,
    size: 9,
    language,
    ...params,
    watch: params,
  });

  const articles = data?.body?.blogs || [];
  const pagination = data?.pagination;
  const getPageLink = number => {
    return `${location.pathname}?${qs.stringify({
      ...queryParams,
      page: number,
    })}`;
  };

  return (
    <Layout variant="article">
      <SEO title={t('pageTitle.articles')} />

      <HeroHeader
        title={t('articles.title1')}
        subtitle={t('articles.title2')}
        image={carnetDeVoyage}
      />

      <BackgroundLight variant="article">
        <Container>
          <Filters />
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {
                (articles ?? []).length === 0 ? <TitleContainer bold small noPaddingLeft>
                  {t('articles.noMatch')}
                </TitleContainer>:''
              }

              <ArticleCards
                columns={3}
                articles={articles.map(article => ({
                  ...article,
                  excerpt: sanitizeHtml(get(article, 'description'), {
                    allowedTags: [],
                    allowedAttributes: {},
                  }).substring(0, 100),
                }))}
                background={'#f7f7f7'}
                variant="article"
              />
              <Pagination
                page={page}
                totalPages={pagination.totalPages}
                pageLinkBuilder={getPageLink}
              />
            </>
          )}
        </Container>
      </BackgroundLight>
    </Layout>
  );
};

export default Blog;
