import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { formatSearch } from '../utils';
import { navigate } from '@reach/router';
import { Formik, Form } from 'formik';
import { Select } from './Select';
import { useLocation } from 'react-use';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import { getThematics } from '../api/articles';
import PageContext from '../contexts/page';
import { useQueryParams } from 'hooks';
import { getCountries } from '../api/countries';

const DesignTextFilterBy = styled.div`
  color:gray;
`;

const BlogFilters = () => {
  const { language } = useContext(PageContext);
  const thematicsResult = useAsync({ promiseFn: getThematics, language });
  const countriesResult= useAsync({ promiseFn: getCountries, language });
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const { pathname } = useLocation();

  const { thematic, countryId} = queryParams;

  const handleFilters = values => {
    const { kind, id, ...filters } = values;
    const search = formatSearch({
      ...filters,
    });

    navigate(`${pathname}?${search}`);
  };

  const handleChange = (value, inputType, setFieldValue) => {
    setFieldValue(inputType, value);
  }

  const getInitialValues = () => {
    let initialValues = {};
    if (Boolean(countryId)) {
      initialValues = {
        ...initialValues,
        countryId: Number(countryId)
      };
    }
    if (Boolean(thematic)) {
      initialValues = {
        ...initialValues,
        thematic: Number(thematic)
      };
    }

    return initialValues;
  };

  const locationItems = useMemo(
    () => {
      let items = [];
      if (countriesResult.data && countriesResult.data.countries) {
        countriesResult.data.countries.filter((elem)=> elem.destination).sort((a,b) => a.displayName.localeCompare(b.displayName)).forEach((country) => {
          items.push({
            value:country.id,
            label:country.displayName
          });
        })
      }
      return items;
    },
    [countriesResult],
  );

  const thematics = useMemo(()=>{
    if (thematicsResult.data){
      return thematicsResult.data.thematics;
    }
    return [];
  },[thematicsResult]);

  return (
    <Formik initialValues={getInitialValues()} onSubmit={(values) => {
      handleFilters(values);
    }}>
      {({ values, setFieldValue, submitForm  }) => (
        <Form>
           <div className="flex space-x-4">
             <div className="flex items-center">
               <DesignTextFilterBy>{t('hotel.filters.titlefiterarticle')}</DesignTextFilterBy>
             </div>

             <div className="w-fit">
               <Select
                 name="location"
                 onChange={input => {
                   handleChange(input.value, 'countryId', setFieldValue);
                   submitForm();
                 }}
                 options={[
                   { label: t('articles.location.unselected'), value: '' },
                   ...locationItems
                 ]}
                 value={values.countryId}
                 placeholder={t('articles.place')}
                 variant={'article'}
                 type={'article'}
               />
             </div>

             <div className="w-fit">
               <Select
                 name="thematic"
                 onChange={input => {
                   handleChange(input.value, 'thematic', setFieldValue)
                   submitForm();
                 }}
                 options={[
                   { label: t('articles.thematic.unselected'), value: '' },
                   ...(thematics || []).map(result => ({
                     label: result.displayName,
                     value: result.id,
                   })),
                 ]}
                 value={values.thematic}
                 placeholder={t('utils.thematic')}
                 variant={'article'}
                 type={'article'}
               />
             </div>
           </div>
        </Form>
      )}
    </Formik>
  );
};

export default BlogFilters;
